<template>
    <footer class="footer">
      <div class="footer-left">
        <div class="logo">GoldenUX</div>
        <div class="social-icons">
          <a href="#"><img src="@/assets/Vectorthree.png" alt="LinkedIn"></a>
          <a href="#"><img src="@/assets/Vectortwo.png" alt="Instagram"></a>
          <a href="#"><img src="@/assets/Vectorone.png" alt="Facebook"></a>
        </div>
      </div>
  
      <div class="footer-right">
        <nav class="footer-nav">
          <div class="company-links">
            <span>Company</span>
            <a href="https://forms.gle/uDowymEfkXSq9JZj6">Work as a tester</a>
            <a href="https://forms.gle/uDowymEfkXSq9JZj6">Contact us</a>
          </div>
          <div class="legal-links">
            <span>Legal</span>
            <a href="#privacy-policy">Privacy Policy</a>
            <a href="#terms">Terms of Service</a>
          </div>
        </nav>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "PageFooter",
  };
  </script>
  
  <style scoped>
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    color: #757575;
    border-top: 4px solid #E2DCC1;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ec8c17;
    margin-bottom: 1rem;
    font-family: "Public Sans", sans-serif;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons img {
    width: 24px;
    height: 24px;
    filter: invert(20%);
  }
  
  .social-icons img:hover {
    filter: invert(0);
  }
  
  .footer-right {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
  
  .footer-nav {
    display: flex;
    gap: 2rem;
  }
  
  .company-links,
  .legal-links {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding-right: 2rem;
    text-align: left;
    font-family: "Public Sans", sans-serif;
  }

  .company-links span, .legal-links span {
    font-weight: 500;
    color: #28211B;
  }
  
  .footer-nav a {
    padding-top: .75rem;
    text-decoration: none;
    font-size: 1rem;
    color: #7c7a7a;
  }
  
  .footer-nav a:hover {
    color: #ec8c17;
  }
  @media (max-width: 768px) {
  .footer {
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-right {
    justify-content: center;
  }

  .footer-nav {
    flex-direction: column;
    gap: 1rem;
  }

  .company-links,
  .legal-links {
    padding-right: 0;
    align-items: center;
  }

  .footer-nav a {
    font-size: 0.9rem;
  }
}


  </style>
  