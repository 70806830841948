<template>
  <div id="app">
    <Header />
    <index msg="Empowering UX Testing with Experience" />
    <Footer />
  </div>
</template>

<script>
import Header from './components/PageHeader.vue';
import index from './components/indexPage.vue';
import Footer from './components/PageFooter.vue'; 

export default {
  name: 'App',
  components: {
    Header,  
    index,  
    Footer  
  }
};
</script>

<style>

#app {
  overflow-x: hidden; /* Peidab horisontaalse kerimise */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
