<template>
  <div>
    <main>
      <section class="hero">
        <h1>Empowering UX Testing with Experience</h1>
        <div class="buttons">
          <a href="https://forms.gle/uDowymEfkXSq9JZj6" class="btn-primary">Get started</a>
        </div>
      </section>

      <section class="statistics">
  <h2>The world is aging, We need to account for that.</h2>
  <div class="stats">
    <div class="stat-item">
      <span class="nr-wrapper">
        <span class="nr" data-target="16">0%</span>
        <span class="reference"><a href="https://www.un.org/en/global-issues/ageing" target="_blank">[1]</a></span>
      </span>
      <br />of people will be over 65 years of age by 2050 globally
    </div>
    <div class="stat-item">
      <span class="nr-wrapper">
        <span class="nr" data-target="68">0%</span>
        <span class="reference"><a href="https://www.aarp.org/pri/topics/technology/internet-media-devices/2023-technology-trends-older-adults/" target="_blank">[2]</a></span>
      </span>
      <br />of people aged 50+ don’t believe technology is designed with them in mind
    </div>
    <div class="stat-item">
      <span class="nr-wrapper">
        <span class="nr" data-target="75">0%</span>
        <span class="reference"><a href="https://www.pewresearch.org/short-reads/2022/01/13/share-of-those-65-and-older-who-are-tech-users-has-grown-in-the-past-decade/" target="_blank">[3]</a></span>
      </span>
      <br />of seniors aged 65+ use the internet daily
    </div>
  </div>
</section>


      <section class="why-choose-us">
        <h2>Why choose us?</h2>
        <div class="features">
          <div class="featureTop">
            <img :src="require('@/assets/ruler.png')" />
            <h3>Experienced Testers</h3>
          </div>
          <div class="featureTop">
            <img :src="require('@/assets/award.png')" />
            <h3>Affordable Pricing</h3>
          </div>
        </div>
        <div class="features">
          <div class="featureBottom">
            <img :src="require('@/assets/speed.png')" />
            <h3>Quick Turnaround</h3>
          </div>
          <div class="featureBottom">
            <img :src="require('@/assets/Vector.png')" />
            <h3>Detailed Professional Reports</h3>
          </div>
          <div class="featureBottom">
            <img :src="require('@/assets/react.png')" />
            <h3>Real Senior User Feedback</h3>
          </div>
        </div>
      </section>

      <section class="cta">
        <h2 id="animated-text" class="animated-text"></h2>
        <div class="buttons" style="justify-content: flex-start;">
          <a href="https://forms.gle/uDowymEfkXSq9JZj6" class="btn-big">Get started</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  mounted() {
    // Number animation
    const counters = document.querySelectorAll(".nr");

    const animateNumbers = () => {
      counters.forEach((counter) => {
        const target = +counter.getAttribute("data-target");
        const speed = 200;
        const increment = Math.ceil((target - counter.innerText.replace('%', '')) / speed);

        const updateCount = () => {
          const current = +counter.innerText.replace('%', '') || 0;

          if (current < target) {
            counter.innerText = `${Math.min(current + increment, target)}%`;
            setTimeout(updateCount, 30);
          }
        };

        updateCount();
      });
    };

    const handleScroll = () => {
      const statsSection = document.querySelector(".statistics");
      const features = document.querySelectorAll(".features div");

      const windowHeight = window.innerHeight;

      if (statsSection.getBoundingClientRect().top < windowHeight) {
        statsSection.classList.add("visible");
        animateNumbers();
      }

      features.forEach((feature) => {
        const featureTop = feature.getBoundingClientRect().top;

        if (featureTop < windowHeight) {
          feature.classList.add("slide-in");
        }
      });

      const allAnimated = [...features].every((feature) =>
        feature.classList.contains("slide-in")
      );
      if (statsSection.classList.contains("visible") && allAnimated) {
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    // Experience matters animation with mistakes
    const textElement = document.getElementById("animated-text");
    const text = "Experience matters.";
    let index = 0;
    let mistakeIndex = Math.floor(Math.random() * text.length);
    let mistakeIndex2 = Math.floor(Math.random() * text.length);

    const animateText = () => {
      if (index < text.length) {
        if (index === mistakeIndex2) {
          // Introduce a wrong letter
          textElement.textContent += String.fromCharCode(
            text.charCodeAt(index) + 1
          );
          setTimeout(() => {
            textElement.textContent = textElement.textContent.slice(0, -1);
          }, 300);
          setTimeout(() => {
          
            animateText();
          }, 500);
          mistakeIndex2=-1;
        } else if(index === mistakeIndex){
          // Introduce a wrong letter
          textElement.textContent += String.fromCharCode(
            text.charCodeAt(index) + 1
          );
          setTimeout(() => {
            textElement.textContent = textElement.textContent.slice(0, -1);
          }, 300);
          setTimeout(() => {
          
            animateText();
          }, 500);
          mistakeIndex=-1;
        }
        else{
          // Add correct letter
          textElement.textContent += text[index];
          index++;
          setTimeout(animateText, 100);
        }
      } else {
        let blinkCount = 0;
        const blinkInterval = setInterval(() => {
          textElement.textContent = textElement.textContent.endsWith(".")
            ? textElement.textContent.slice(0, -1)
            : textElement.textContent + ".";
          blinkCount++;
          if (blinkCount > 5) {
            clearInterval(blinkInterval);
          }
        }, 500);
      }
    };
// Kasutame IntersectionObserverit, et jälgida, kui element on nähtav
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Kui element on nähtav, alustame animatsiooni
        animateText();
        // Peatame jälgimise, kuna animatsioon peaks käima ainult üks kord
        observer.unobserve(entry.target);
      }
    });
  },
  { threshold: 0.5 } // Kui 50% elemendist on nähtav, siis alustame
);

// Algse tekstielemendi jälgimine
observer.observe(textElement);
  },
};
</script>

<style scoped>


/* Hero section */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 360px;
  padding-block: 6rem 10rem;
  background-color: #fffddf;
}

.hero h1 {
  font-family: 'Roboto Serif', serif;
  font-size: 3.75rem;
  font-size: clamp(3.75rem, 3rem + 3.75vw, 7.5rem);
  font-weight: 700;
  line-height: clamp(3.75rem, 3rem + 3.75vw, 7.5rem);
  letter-spacing: -0.02em;
  margin-bottom: 2.5rem;
  text-align: center;
  color: #201c19;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.btn-primary, .btn-secondary, .btn-big {
  border: 4px solid #FFAC38;
  background-color: #FFAC38;
  padding: 0.75rem 2rem;
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-family: "Public Sans", sans-serif;
  border-radius: 16px;
  text-align: center;
  transition: all .2s ease-in-out;
  font-size: 1.25rem;
}

.btn-secondary {
  background-color: transparent;
}

.btn-primary:hover, .btn-big:hover {
  background-color: #d97706;
  border-color: #d97706;
}

.btn-secondary:hover {
  background-color: #FFAC38;
}

.statistics {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #27221e;
  text-align: center;
  padding: 5rem 4rem;
  margin-top: 2rem;
}

.statistics.visible {
  opacity: 1;
  transform: translateY(0);
}

.statistics h2 {
  margin: 0;
  color: #FFAC38;
  font-family: 'Roboto Serif', serif;
  font-size: 3rem;
  letter-spacing: -0.02em;
  width: 30ch;
}

.nr {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  gap: 5rem;
  font-size: 1.5rem;
  color: #E1DFD7;
  font-family: "Public Sans", sans-serif;
  margin-inline: 10%;
}

.stats div {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.why-choose-us {
  justify-content: space-between;
  text-align: center;
  padding: 4rem 2rem;
  background-color: #fffddf;
  gap: 2rem;
}

.why-choose-us h2 {
  font-family: 'Roboto Serif', serif;
  font-size: 45px;
  color: #27221e;
  margin-bottom: 2rem;
}

.features {
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 32px;
}

.featureTop {
  width: 36vw;
  height: 8rem;
  text-align: left;
  padding: 2.75rem;
  border: 4px solid #FFAC38;
  border-radius: 20px;
  background-color: #e7e5c8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.featureBottom {
  width: calc((72vw - 130px) / 3);
  height: 8rem;
  text-align: left;
  padding: 2.75rem;
  border: 4px solid #FFAC38;
  border-radius: 20px;
  background-color: #e7e5c8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(+50%);
  transition: opacity 1s ease-out, transform 1s ease-out;
} 

.featureTop h3, .featureBottom h3 {
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: #28211B;
}

.featureTop.slide-in,
.featureBottom.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.cta {
  padding: 2rem;
  justify-items: left;
  padding-left: calc(14vw - 2.5rem - 32px);
  padding-bottom: 10rem;
}

.cta h2 {
  font-family: 'Roboto Serif', serif;
  font-size: 130px;
  max-width: min-content;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -0.02em;
  margin-bottom: 2.5rem;
  color: #201c19;
  text-align: left;
  padding-bottom: 1rem;
}

.animated-text {
  font-size: 3rem;
  font-weight: bold;
  color: #201c19;
  text-align: left;
  white-space: pre-wrap;
}

.stat-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nr-wrapper {
  position: relative;
  display: inline-block;
}

.reference {
  position: absolute;
  top: -12px; /* Viite asukoht protsendi kohal */
  right: -10px; /* Parempoolne joondus */
  font-size: 0.75rem; /* Väiksem viide */
  color: #FFAC38; /* Viite värv oranžiks */
  text-decoration: none; /* Eemaldab allajoonimise */
}

.reference a {
  color: inherit; /* Säilitab oranži värvi viite lingil */
  text-decoration: none; /* Eemaldab allajoonimise */
}

.reference a:hover {
  text-decoration: underline; /* Lisab allajoonimise hõljutamisel */
}
@media (max-width: 768px) {
  /* Hero Section */
  .hero {
    padding-block: 4rem 6rem;
    height: auto;
  }

  .hero h1 {
    font-size: 2.5rem; /* Vähendatud teksti suurus */
    line-height: 1.2;
  }

  .buttons {
    gap: 1rem;
  }

  .btn-primary,
  .btn-secondary,
  .btn-big {
    padding: 0.5rem 1.5rem; /* Väiksemad nupud */
    font-size: 1rem;
  }

  /* Statistics Section */
  .statistics {
    padding: 3rem 2rem;
  }
  .statistics h2 {
    width: 90%;
  }

  .stats {
    flex-direction: column; /* Virnastab statistika vertikaalselt */
    gap: 2rem; /* Vähendab vahet */
    margin-inline: 0;
  }

  .stats div {
    width: 100%;
    text-align: center;
  }

  /* Why Choose Us Section */
  .why-choose-us {
    padding: 2rem 1rem;
  }

  .features {
    flex-direction: column; /* Virnastab omadused vertikaalselt */
    gap: 1.5rem;
  }

  .featureTop,
  .featureBottom {
    width: 82%; /* Täielik laius */
    text-align: center; /* Tekst keskjoonduseks */
    padding: 1.5rem; /* Väiksem sisu polsterdus */
  }

  /* CTA Section */
  .cta {
    padding: 2rem 1rem;
  }

  .cta h2 {
    font-size: 3rem; /* Vähendatud pealkirja suurus */
    line-height: 1.1;
    text-align: left; /* Keskjoonduse pealkirja jaoks */
  }

  .animated-text {
    padding-left: 1rem;
    font-size: 2rem; /* Vähendatud animatsiooni teksti suurus */
  }

  .buttons {
    padding-left: 0.8rem;
    justify-content: center;
  }
}




</style>
