<template>
  <header class="header" :style="{ opacity: headerOpacity, pointerEvents: headerOpacity === 0 ? 'none' : 'auto' }">
    <div class="logo">GoldenUX</div>
    <!-- <nav class="nav">
      <a href="#home">Home</a>
      <a href="#about">About</a>
      <a href="#contact">Contact</a>
    </nav> -->
    <div class="buttons">
      <a href="https://forms.gle/uDowymEfkXSq9JZj6" class="btn-secondary">Log In</a>
      <a href="https://forms.gle/uDowymEfkXSq9JZj6" class="btn-primary">Get started</a>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      lastScrollTop: 0, // Hoiame meeles viimast kerimispositsiooni
      headerOpacity: 1 // Algne nähtavus (täielik nähtavus)
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // Lisame kerimisürituse
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Eemaldame kerimisürituse, kui komponent eemaldatakse
  },
  methods: {
    handleScroll() {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

      // Arvutame, kui palju on lehte keritud, ja määrame vastava läbipaistvuse väärtuse.
      let opacityValue = 1 - currentScroll / 400; // Muuda 500 väärtust vastavalt sellele, kui kiiresti soovid, et päis hajuks

      // Tagame, et läbipaistvus ei läheks alla 0 ega ületa 1
      if (opacityValue < 0) opacityValue = 0;
      if (opacityValue > 1) opacityValue = 1;

      this.headerOpacity = opacityValue;

      this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Veendume, et ei läheks negatiivseks
    }
  }
};
</script>

<style scoped>
.header {
    top: 20px;
    display: flex;
    justify-content: space-between; /* Paigutab logo vasakule, nupud paremale */
    align-items: center;
    padding-left: 20px;
    color: #27221e;
    position: fixed;
    gap: 50px;
    width: 100%;
    z-index: 1000;
    font-family: "Public Sans", sans-serif;
}
  
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ec8c17;
  padding-right: 2rem;
}

.nav {
  gap: 50px;
  display: flex; /* Paigutame lingid horisontaalselt */
  flex-grow: 1;  /* Võtab ülejäänud vaba ruumi */
}

.nav a {
  color: #27221e;
  text-decoration: none;
  font-size: 1rem;
  transition: all .2s ease-in-out;
}

.nav a:hover {
  color: #ec8c17; /* Esiletõstmine hiirega */
}

.buttons {
  display: flex;
  gap: 40px;
  padding-right: 4rem;
  justify-content: flex-end; /* Paigutab nupud paremale */
}

.btn-primary {
  background-color: #FFAC38;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  color: black;
  border-radius: 12px;
  transition: all .2s ease-in-out;
}

.btn-secondary {
  color: black;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 12px;
  transition: all .2s ease-in-out;
}

.btn-primary:hover {
  background-color: #d97706;
}

.btn-secondary:hover {
  background-color: #ec8c17;
}
@media (max-width: 768px) {
  
  .header {
    top: 10px;
    padding: 1 0rem; /* Vähendab ääri */
    gap: 1rem; /* Lisab ruumi elementide vahele */
  }

  .logo {
    font-size: 1.2rem; /* Vähendab logo suurust */
    padding-right: 0; /* Eemaldab liigse parema ääre */
  }

  .buttons {
    gap: 0.75rem; /* Vähendab nuppude vahelist ruumi */
    padding-right: 2rem; /* Eemaldab liigse parema ääre */
  }

  .btn-primary,
  .btn-secondary {
    padding: 0.5rem 1rem; /* Vähendab nuppude suurust */
    font-size: 0.9rem; /* Vähendab teksti suurust nuppudes */
  }
}


</style>
